<template>
    <div
      class="column is-3">
        <div class="box">
          <figure class="image mb-4">
            <img :src="product.get_thumbnail" :alt="product.name" />
          </figure>

          <h3 class="is-size-4">{{ product.name }}</h3>
          <p class="is-size-6 has-text-grey">€{{ product.price }}</p>

          <router-link :to="product.get_absolute_url" class="button is-dark mt-4"> Vedi dettagli </router-link>
        </div>
      </div>
</template>

<script>
export default {
  name: 'ProductBox',
  props: {
    product: {
      type: Object,
    },
  },
}
</script>

<style scoped>
  .image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    /*height: 237px;*/
  }

  img {
    height: 237px;
    /*object-fit: cover;
    aspect-ratio: 1/1;*/
  }

</style>